import fetch from "auth/FetchInterceptor";

const UserService = {};

//get lits of user members (friends)
UserService.getMembers = function () {
    return fetch({
        url: "/user/members",
        method: "GET",
    });
}

//remove one of the user members. the code is for the deleted member .
UserService.removeMember = function ($code) {
    return fetch({
        url: "/user/member/" + $code,
        method: "DELETE",
    });
}

//add a member to the user the code is for the added member .
UserService.addMember = function ($code) {
    return fetch({
        url: "/user/member/" + $code,
        method: "POST",
    });
}

UserService.linkGoogleCalender = function ($code) {
    return fetch({
        url: "/user/link-Calendar",
        method: "POST",
        data: { code : $code}
    });
}

//get the joined team in an organisation
UserService.getTeams = function () {
    return fetch({
        url: "/user/teams",
        method: "GET",
    });
}

// the user change his password 
UserService.changePassword = function (params) {
  return fetch({
    url: "/user/password",
    method: "Post",
    data: params,
  });
};

//the user change his image  
UserService.changeImage = function (data) {
  return fetch({
    url: "/user/change_image",
    method: "post",
    data: data,
  });
};

//the user update his account info 
UserService.updateProfile = function (data) {
  return fetch({
    url: "/user/update",
    method: "put",
    data: data,
  });
};

//the admin get the users static 
UserService.static = function () {
  return fetch({
    url: "/user/static",
    method: "get",
  });
};

//the admin get all the users
UserService.getAll = function () {
  return fetch({
    url: "/user/getAllUsers",
    method: "get",
  });
};

//the admin add a user 
UserService.AddUser = function (data) {
  return fetch({
    url: "/user/admin/add",
    method: "post",
    data: data,
  });
};

//the admin remove a user 
UserService.removeUser = function (id) {
  return fetch({
    url: `/user/admin/remove/${id}`,
    method: "delete",
  });
};

//the admin update a specific user info
UserService.updateUser = function (data, id) {
  return fetch({
    url: `/user/admin/update/${id}`,
    method: "put",
    data: data,
  });
};

// the user get his own static (his plan info)
UserService.MyStatic = function () {
  return fetch({
    url: "/user/MyStatic",
    method: "get",
  });
};

//Payment Steps
UserService.paymentUrl = function (id,data) {
  return fetch({
    url: `/user/paymentUrl/${id}`,
    method: "post",
    data:data
  });
};

UserService.verifyPayment = function (code) {
  return fetch({
    url: `/user/verifyPayment/${code}`,
    method: "get",
  });
};

UserService.buyPlan = function (code) {
  return fetch({
    url: `/user/buyPlan/${code}`,
    method: "post",
  });
};

UserService.buyFeature = function (code) {
  return fetch({
    url: `/user/buyFeature/${code}`,
    method: "post",
  });
};

UserService.backToFree = function () {
  return fetch({
    url: "/user/backToFree",
    method: "post",
  });
};

UserService.forgotPassword = function (data) {
  return fetch({
    url: "/user/forgotPassword",
    method: "put",
    data: data,
  });
};

UserService.createGuest = function (data) {
  return fetch({
    url: "/user/createNewGuest",
    method: "post",
    data: data,
  });
};

UserService.userExiste = function (data) {
  return fetch({
    url: "/user/userExiste",
    method: "post",
    data: data,
  });
};

export default UserService;
