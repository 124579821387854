import React, { useEffect, useRef, useState } from 'react';
import { JitsiMeeting } from '@jitsi/react-sdk';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, Card, Form, Input, message, Modal } from "antd";
import { VideoCameraOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import MeetServices from "../../services/MeetServices";
import Loading from "../../components/shared-components/Loading";
import UserService from 'services/UserService';
import IntlMessage from 'components/util-components/IntlMessage';
import AiModel from './aiModel';

const RoomMeet = (props) => {
    const { location, Data } = props;
    const [form] = Form.useForm();
    const { state } = location;
    const [meetData, setMeetData] = useState(Data?Data:state);
    const [hasAi, setHasAi] = useState(Data?.hasAi?Data?.hasAi:false);
    const { username, name } = useSelector(state => ({
        username: state.auth.username,
        name: state.auth.displayName
    }));
    const [discussion, setDiscussion] = useState('');
    const AiModal = location.state?.hasAi|| false;
    const [displayName, setDisplayName] = useState(username);

    const queryParams = new URLSearchParams(location.search);
    const querryRef = queryParams.get('id');
    const [ref, setRef] = useState(querryRef);

    const backgroundStyle = {
        backgroundImage: 'url(/img/others/img-17.jpg)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'
    };
    const history = useHistory();
    const YOUR_DOMAIN = 'tunimeet.francecentral.cloudapp.azure.com';
    //const YOUR_DOMAIN = 'meet.jit.si';

    const discussionRef = useRef(discussion); // Create a ref
    const setLocale = (isLocaleOn, localeKey) =>
        isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();

    let urlParams = new URLSearchParams(location.search);
    let idValue = urlParams?.get('id');
    let passwordValue = urlParams.get('password');
    let emailValue = urlParams.get('email');

    useEffect(() => {
        if (username === "" && emailValue) {
            UserService.userExiste({ username: emailValue }).then(resp => {
                if (!resp.data.existe) {
                    const { confirm } = Modal;
                    confirm({
                        title: "You don't have an account on this website. Do you want to create one?",
                        icon: <ExclamationCircleOutlined />,
                        onOk() {
                            UserService.createGuest({ username: emailValue }).then(() => {
                                message.success("Account created successfully");
                            });
                        },
                        onCancel() {
                            console.log('Cancel');
                        },
                    });
                }
            });
        }
    }, [username, emailValue]);

    useEffect(() => {
        discussionRef.current = discussion; // Update the ref whenever discussion changes
    }, [discussion]);

    const updateDiscussionRef = (transcript) => {
        discussionRef.current = transcript;
    };

    const handleStartMeeting = () => {
        form.validateFields()
            .then(values => {
                MeetServices.joinMeeting(values)
                    .then(resp => {
                        setMeetData(resp.data.meet);
                        if (!ref) {
                            setRef(resp?.data?.meet?.ref);
                        }
                        setDisplayName(values.displayName);
                        setHasAi(resp.data.hasAi);
                        history.push({ pathname: '/room', search: '?id=' + resp?.data?.meet?.ref });
                        // window.open('/room?id=' + resp?.data?.meet?.ref, '_blank');
                    })
                    .catch(errorInfo => {
                        console.log(errorInfo);
                    });
            })
            .catch(errorInfo => {
                console.log(errorInfo);
            });
    };


    if (meetData === undefined) {
        return (
            <div style={backgroundStyle}>
                <div className={'container d-flex flex-column justify-content-center'} style={{ height: '100vh', width: 500 }}>
                    <Card centered>
                        <div className="text-center">
                            <img className="img-fluid mb-3" src={`/img/logo.png`} alt="" />
                            {!username &&
                                <p>{setLocale(true, "login.dontHaveAccount")} <a href={'/auth/register-1'}>{setLocale(true, "profile.signup")}</a></p>
                            }
                        </div>
                        <Form form={form} layout="vertical" requiredMark={false}>
                            <Form.Item
                                name="displayName"
                                label={setLocale(true, "profile.displayName")}
                                rules={[{ required: true, message: 'Please enter display name' }]}
                                initialValue={emailValue ? '' : name}
                            >
                                <Input placeholder="Please enter display name" />
                            </Form.Item>
                            <Form.Item
                                name="email"
                                label={setLocale(true, "profile.email")}
                                rules={[{ required: true, message: 'Please enter email name' }]}
                                initialValue={emailValue ? emailValue : username}
                            >
                                <Input placeholder="Please enter email name" />
                            </Form.Item>
                            <Form.Item
                                name="roomRef"
                                label={setLocale(true, "ref")}
                                rules={[{ required: true, message: 'Please enter room reference' }]}
                                initialValue={idValue}
                            >
                                <Input placeholder="Please enter room reference" />
                            </Form.Item>
                            <Form.Item
                                name="password"
                                label={setLocale(true, "login.password")}
                                initialValue={passwordValue}
                            >
                                <Input.Password placeholder="Please enter password" />
                            </Form.Item>
                            <div>
                                <Button type={'primary'} onClick={handleStartMeeting} icon={<VideoCameraOutlined />}>
                                    Start Meeting
                                </Button>
                            </div>
                        </Form>
                    </Card>
                </div>
            </div>
        );
    }

    return (
        <>
            {(AiModal || hasAi) && (
                <AiModel
                    onSaveDiscussion={setDiscussion}
                    updateDiscussionRef={updateDiscussionRef}
                    meetingCreator={meetData?.createdBy}
                    username={username}
                />
            )}
            <JitsiMeeting
                domain={YOUR_DOMAIN}
                roomName={meetData?.ref}
                spinner={() => <Loading cover="page" />}
                configOverwrite={{
                    startWithAudioMuted: false,
                    disableModeratorIndicator: true,
                    startScreenSharing: true,
                    enableEmailInStats: false,
                    callDisplayName: meetData?.name,
                    prejoinPageEnabled: false,
                }}
                interfaceConfigOverwrite={{
                    DISABLE_JOIN_LEAVE_NOTIFICATIONS: true,
                }}
                userInfo={{
                    displayName: displayName
                }}
                onApiReady={(externalApi) => {
                    // here you can attach custom event listeners to the Jitsi Meet External API
                    // you can also store it locally to execute commands
                    // listen for an event
                    externalApi.addEventListener('readyToClose', () => {
                        const email = username !== "" ? username : form.getFieldValue('email');
                        const meetingInfo = {
                            meetRef: ref,
                            username: email,
                            discussion: discussionRef.current
                        };
                        MeetServices.endMeeting(meetingInfo).then(() => {
                            // history.push('/app/home');
                            setTimeout(() => {
                                window.close();
                            }, 2000);
                        });
                    });

                    externalApi.addEventListener('videoConferenceLeft', (obj) => {
                        const email = username !== "" ? username : form.getFieldValue('email');
                        const meetingInfo = {
                            meetRef: obj.roomName,
                            username: email,
                            discussion: discussionRef.current
                        };
                        MeetServices.endMeeting(meetingInfo).then(() => {
                            // history.push('/app/home');
                            setTimeout(() => {
                                window.close();
                            }, 2000);
                        });
                    });
                }}
                getIFrameRef={(iframeRef) => {
                    iframeRef.style.height = '100vh';
                }}
            />
        </>
    );
};

export default RoomMeet;