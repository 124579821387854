import React, { useState } from "react";
import {
    Button,
    Modal,
    Form,
    Input,
    message,
    Upload,
} from "antd";
import { useDispatch } from "react-redux";
import { ChangeOrganisation } from "redux/actions";
import {
    PlusOutlined,

} from '@ant-design/icons';
import OrganisationService from "services/OrganisationService";

const JoinModel = ({ visible, close }) => {
    const [form] = Form.useForm();
    const [loading,setLoading] = useState(false);
    const [join, setJoin] = useState(true);
    const dispatch = useDispatch();

    const handleSendRequest = () => {
        const { code } = form.getFieldsValue(['code']);
        OrganisationService.SendJoinRequest(code).then((resp) => {
            message.success(resp.data.message);
            form.resetFields();
        });
    };

    const handleCreateOrganisation = () => {
        const { name, phone, icon } = form.getFieldsValue(['name', 'phone', 'icon']);
        const formData = new FormData();
        formData.append('name', name);
        formData.append('phone', phone);
        if (icon && icon.fileList.length > 0) {
            formData.append('icon', icon.fileList[0].originFileObj);
        }
        setLoading(true);
        OrganisationService.CreateOrganisation(formData).then((resp) => {
                setLoading(false);
                message.success('Organisation created successfully');
                setTimeout(() => {
                    window.location.assign("/app/home");
                }, 100);
        });
    };

    const uploadprops = {
        multiple: false,
        customRequest: ({ onSuccess }) => { onSuccess(); },
    };

    return (
        <Modal
            visible={visible}
            width={650}
            closable={false}
            title={join ? 'Join Organisation' : 'Create Organisation'}
            footer={[
                <Button key="back" className="btn btn-light" onClick={close}>
                    Cancel
                </Button>,
                <Button type={'primary'} onClick={() => {
                    form.validateFields(['code'])
                        .then((values) => {
                            if (Object.values(values).every(value => value)) {
                                handleSendRequest();
                                setJoin(true);
                            } else {
                                setJoin(true);
                            }
                        })
                        .catch(() => {
                            message.error('Please put the code');
                            setJoin(true);
                        });
                }} >
                    Join
                </Button>,
                <Button
                    type={'primary'}
                    ghost
                    onClick={() => {
                        form.validateFields(['name', 'phone'])
                            .then((values) => {
                                if (Object.values(values).every(value => value)) {
                                    handleCreateOrganisation();
                                    setJoin(false);
                                } else {
                                    setJoin(false);
                                }
                            })
                            .catch(() => {
                                message.error('Please fill all the fields');
                                setJoin(false);
                            });
                    }}
                    loading={loading}
                >
                    Create
                </Button>
            ]}
            centered
        >
            <Form form={form} labelCol={{ span: 8 }}>
                {join ?
                    <Form.Item name={'code'} label={'Organisation Code'} rules={[
                        {
                            required: true,
                            message: 'Please input the organisation code'
                        }
                    ]}>
                        <Input />
                    </Form.Item>
                    :
                    <>
                        <Form.Item name={'name'} label={'Organisation Name'} rules={[
                            {
                                required: true,
                                message: 'Please input the organisation name'
                            }
                        ]}>
                            <Input />
                        </Form.Item>
                        <Form.Item name={'phone'} label={'Organisation phone'} rules={[
                            {
                                required: true,
                                message: 'Please input the organisation phone'
                            }
                        ]}>
                            <Input />
                        </Form.Item>
                        
                        <Form.Item name={'icon'} label={'Organisation icon'}>

                            <Upload
                                {...uploadprops}
                                accept=".jpg,.jpeg,.png"
                                listType="picture-card"
                                maxCount={1}
                            >
                                <button
                                    style={{
                                        border: 0,
                                        background: 'none',
                                    }}
                                    type="button"
                                >
                                    <PlusOutlined />
                                    <div style={{ marginTop: 8 }}>Upload</div>
                                </button>
                            </Upload>
                        </Form.Item>
                    </>
                }
            </Form>
        </Modal>
    )
}

export default JoinModel;