import React, { useEffect, useState } from 'react';
import { Button, Input } from 'antd';
const speechsdk = require('microsoft-cognitiveservices-speech-sdk')

const { TextArea } = Input;

const AiModel = ({ onSaveDiscussion, updateDiscussionRef, meetingCreator, username }) => {
    const [hide, setHide] = useState(false);
    const [displayText, setDisplayText] = useState('');



    useEffect(() => {
        const speechConfig = speechsdk.SpeechConfig.fromSubscription(
            "3O9tNApGd2FU3lybrWcAA9f9UTdIhtj2SwJOp3Rp1IJjYEVMxBIHJQQJ99AKAC5T7U2XJ3w3AAAYACOGacK4",
            "francecentral"
        );
        speechConfig.speechRecognitionLanguage = 'ar-TN';

        const audioConfig = speechsdk.AudioConfig.fromDefaultMicrophoneInput();
        const recognizer = new speechsdk.SpeechRecognizer(speechConfig, audioConfig);

        recognizer.startContinuousRecognitionAsync(
            () => {
                console.log("Continuous recognition started");
            },
            (err) => {
                console.error("Error starting continuous recognition: ", err);
                setDisplayText('ERROR: Failed to start continuous recognition.');
            }
        );

        recognizer.recognized = (s, e) => {

            if (e.result.reason === speechsdk.ResultReason.RecognizedSpeech) {
                // onRecognizedResult();
                // setDisplayText(displayText + e.result.text);
                const now = new Date();
                const formattedDate = new Intl.DateTimeFormat('en-GB', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: false
                }).format(now).replace(',', ':');
                setDisplayText((prevDisplayText) => `${prevDisplayText} ${e.result.text} ${formattedDate}`);
            }
        };

        return () => {
            recognizer.stopContinuousRecognitionAsync(
                () => {
                    console.log("Continuous recognition stopped");
                    // onRecognizedResult();
                },
                (err) => {
                    console.error("Error stopping continuous recognition: ", err);
                }
            );
        };
    }, []);

    useEffect(() => {
        updateDiscussionRef(displayText);
    }, [displayText, updateDiscussionRef]);

    const handleHide = () => {
        setHide(!hide);
    }

    return (
        <>
            {meetingCreator === username && hide && (
                <div>
                    <TextArea
                        value={displayText}
                        className='bg-white position-absolute ml-4'
                        style={{ width: '15rem', top: '25vh', height: '50%' }}
                        readOnly
                    />
                </div>
            )}
            {meetingCreator === username && (
                <div className='position-absolute ml-4' style={{ top: '80vh' }}>
                    <Button className='mr-1' onClick={handleHide}>
                        {hide ? 'Hide' : 'Show'} AI model
                    </Button>
                </div>
            )}
        </>
    );
};

export default AiModel;