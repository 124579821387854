import axios from "axios";
import { API_BASE_URL, NOT_FOUND_PATH } from "configs/AppConfig";
import history from "../history";
//import { AUTH_TOKEN } from "redux/constants/Auth";
import { notification } from "antd";
import store from '../redux/store';
import { signOut, ChangeOrganisation } from "../redux/actions";

const service = axios.create({
  baseURL: API_BASE_URL,
  timeout: 60000,
  withCredentials: true,
});

// Config
const ENTRY_ROUTE = "/auth/login";
//const TOKEN_PAYLOAD_KEY = "authorization";
const PUBLIC_REQUEST_KEY = "public-request";

// API Request interceptor
service.interceptors.request.use(
  (config) => {
    if (config.headers[PUBLIC_REQUEST_KEY]) {
      config.withCredentials = false;
    }
    return config;
  },
  (error) => {
    console.log(error);
    // Do something with request error here
    notification.error({
      message: "Error",
    });
    Promise.reject(error);
  }
);

// API respone interceptor
service.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    let notificationParam = {
      message: "Server Error please try again",
    };
    if (error.response) {
      // Remove token and redirect
      if (error.response.status === 403) {
        //notificationParam.message = "Authentication Fail";
        //notificationParam.description = "Please login again";
        //history.push(ENTRY_ROUTE);
        //window.location.reload();
        return error.response.status;
      }

      if (error.response.status === 401) {
        if (window.location.pathname === ENTRY_ROUTE) {
          return;
        }
        if (error.response.data?.message === "Missing JWT Refresh Token" && window.location.pathname !== ENTRY_ROUTE) {
          notificationParam.message = "Refresh Token Not Found";
          notificationParam.description = "Please login again";
          store.dispatch(signOut());
          store.dispatch(ChangeOrganisation({
            id: "",
            name: "",
            isOwner: false,
            userType: "",
            code: "",
            icon: "",
            phone: "",
            hasAi: false,
            hasConference: false,
            access: {
              startMeet: false,
              addMember: false,
              updateMember: false,
              removeMember: false,
              addTeam: false,
              removeTeam: false,
              updateTeam: false,
              updateConference: false,
              startConference: false,
              isOrg: false
            }
          }));
          history.push(ENTRY_ROUTE)
        } else if (error.response.data?.message === "JWT Refresh Token Not Found" && window.location.pathname !== ENTRY_ROUTE && window.location.pathname !== '/notfound') {
          notificationParam.message = "UNAUTHORIZED";
          notificationParam.description = "please refresh the page"
          store.dispatch(signOut());
          store.dispatch(ChangeOrganisation({
            id: "",
            name: "",
            isOwner: false,
            userType: "",
            code: "",
            icon: "",
            phone: "",
            hasAi: false,
            hasConference: false,
            access: {
              startMeet: false,
              addMember: false,
              updateMember: false,
              removeMember: false,
              addTeam: false,
              removeTeam: false,
              updateTeam: false,
              updateConference: false,
              startConference: false,
              isOrg: false
            }
          }));
          window.location.reload();
        }
      }

      if (error.response.status === 404) {
        notificationParam.message = "Not Found";
        notificationParam.description = error.response.data.error.details;
        window.location.href = (NOT_FOUND_PATH);
      }

      if (error.response.status === 400) {
        notificationParam.message = "Bad Request";
        notificationParam.description = error.response.data.error.details;
      }

      if (error.response.status === 500) {
        notificationParam.message = "Internal Server Error";

      }

      if (error.response.status === 508) {
        notificationParam.message = "Time Out";
        notificationParam.description = error.response.data.error.details;
      }
    } else {
      notificationParam.message = "No Server Response";
      if (error.response !== undefined) {
        if (error.response.data.error.details !== undefined) {
          notificationParam.description = error.response.data.error.details;
        }
      }
      //localStorage.removeItem(AUTH_TOKEN);
      //history.push(ENTRY_ROUTE);
      //window.location.reload();
    }

    notification.error(notificationParam);

    return Promise.reject(error);
  }
);

export default service;
