import fetch from "auth/FetchInterceptor";

const OrganisationService = {};

OrganisationService.GetOwnedOrganisation = function () {
    return fetch({
        url: "/organisation",
        method: "get",
    });
}

OrganisationService.GetJoinedOrganisations = function () {
    return fetch({
        url: "/organisation/joined",
        method: "get",
    });
}

OrganisationService.SendJoinRequest = function (code) {
    return fetch({
        url: `/organisation/join/${code}`,
        method: "post",
    });
}

OrganisationService.CreateOrganisation = function (data) {
    return fetch({
        url: `/organisation`,
        data,
        method: "post",
    });
}

OrganisationService.GetOrganisationMeets = function (id) {
    return fetch({
        url: `/organisation/meets/${id}`,
        method: "get",
    });
}

OrganisationService.GetOrganisationMembers = function (id) {
    return fetch({
        url: `/organisation/members/${id}`,
        method: "get",
    });
}

OrganisationService.removeMember = function (orgId, code) {
    return fetch({
        url: `/organisation/member/${orgId}/${code}`,
        method: "delete",
    });
}

OrganisationService.addMember = function (id, data) {
    return fetch({
        url: `/organisation/addMemmbers/${id}`,
        method: "post",
        data,
    })
}


OrganisationService.updateMember = function ( id, code,data) {
    return fetch({
        url: `/organisation/updateMember/${id}/${code}`,
        method: "put",
        data,
    })
}



OrganisationService.getMember = function (id, code) {
    return fetch({
        url: `/organisation/member/${id}/${code}`,
        method: "get",
    })
}

OrganisationService.ChangePhoto = function (id, data) {
    return fetch({
        url: `/organisation/change/image/${id}`,
        data,
        method: "post",
    });
}

OrganisationService.UpdateOrganisation = function (id, data) {
    return fetch({
        url: `/organisation/${id}`,
        data,
        method: "put",
    });
}

OrganisationService.RemoveOrganisation = function (id) {
    return fetch({
        url: `/organisation/${id}`,
        method: "delete",
    });
}

OrganisationService.static = function () {
    return fetch({
        url: "/organisation/static",
        method: "get",
    });
}

OrganisationService.getAll = function () {
    return fetch({
        url: "/organisation/all",
        method: "get",
    });
}

OrganisationService.add = function (data) {
    return fetch({
        url: "/organisation/add",
        data,
        method: "post",
    });
}

OrganisationService.update = function (id, data) {
    return fetch({
        url: `/organisation/admin/update/${id}`,
        data,
        method: "put",
    });
}

OrganisationService.getStatic = function (id) {
    return fetch({
        url: `/organisation/static/${id}`,
        method: "get",
    });
}

OrganisationService.paymentUrl = function (orgId, id,data) {
    return fetch({
        url: `/organisation/paymentUrl/${id}/${orgId}`,
        method: "post",
        data:data
    });
};

OrganisationService.verifyPayment = function (code) {
    return fetch({
        url: `/organisation/verifyPayment/${code}`,
        method: "get",
    });
};


OrganisationService.buyPlan = function (orgId, code) {
    return fetch({
        url: `/organisation/buyPlan/${orgId}/${code}`,
        method: "post",
    });
};

OrganisationService.buyFeature = function (orgId, code) {
    return fetch({
        url: `/organisation/buyFeature/${orgId}/${code}`,
        method: "post",
    });
};

OrganisationService.backToFree = function (orgId) {
    return fetch({
        url: `/organisation/backToFree/${orgId}`,
        method: "post",
    });
};

OrganisationService.joinRequests = function (orgId){
    return fetch({
        url: `/organisation/join/requests/${orgId}`,
        method: "get",
    });
}

OrganisationService.acceptRequest= function (id){
    return fetch ({
        url: `/organisation/join/accept/${id}`,
        method: "put",
    })
}

OrganisationService.refuseRequest =function(id){
    return fetch ({
        url: `/organisation/join/refuse/${id}`,
        method: "put",
    })
}

OrganisationService.leave=function (id){
    return fetch ({
        url: `/organisation/leave/${id}`,
        method: "put",
    })
}

export default OrganisationService;