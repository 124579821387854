import React, { useEffect, useState } from "react";
import {
    Menu,
    Dropdown,
    Button,
    Avatar,
    Tooltip,
} from "antd";
import { connect, useDispatch } from "react-redux";
import { ChangeOrganisation } from "redux/actions";
import {
    PlusOutlined,
    BankOutlined,
    RightSquareOutlined
} from '@ant-design/icons';
import OrganisationService from "services/OrganisationService";
import { env } from "configs/EnvironmentConfig";
import JoinModel from '../../views/app-views/home/AddOrganisation';
import IntlMessage from "components/util-components/IntlMessage";
import { useSelector } from "react-redux";
export const NavOrganisation = (props) => {
    const { ChangeOrganisation,
        name,
        icon,
        id,
    } = props;

    const orgId = useSelector(state => state.org.id);

    const [ownedOrganisation, setOwnedOrganisation] = useState([]);
    const [joinedOrganisation, setJoinedOrganisation] = useState([]);
    const dispatch = useDispatch();
    const [showJoinModel, setShowJoinModel] = useState(false)

    const handleClose = () => {
        setShowJoinModel(false);
    };

    const setLocale = (isLocaleOn, localeKey) =>
        isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();

    useEffect(() => {
        OrganisationService.GetJoinedOrganisations().then((response) => {
            setJoinedOrganisation(response.data);
        }).catch((err) => {
            console.log(err.message);
        });
        OrganisationService.GetOwnedOrganisation().then((response) => {
            setOwnedOrganisation(response.data);
        }).catch((err) => {
            console.log(err.message);
        });
    }, []);

    // const userTypeInOrganisation = (organisation) => {
    //     return organisation.members.find((member) => member === username).type;
    // }

    const organisationMenu = (
        <div className="nav-profile nav-dropdown">
            <div className="nav-profile-header d-flex justify-content-between">
                <div className="ml-2" onClick={() => setShowJoinModel(true)} style={{ cursor: 'pointer' }}>
                    <Avatar size={30}><PlusOutlined /></Avatar>
                    <span className="ml-2">{setLocale(true, "organisation.join")}</span>
                </div>
                {orgId &&
                    <div className="ml-2" onClick={(e) => {
                        dispatch(ChangeOrganisation({
                            id: "",
                            name: "",
                            isOwner: false,
                            userType: "",
                            code: "",
                            icon: "",
                            phone: "",
                            hasAi: false,
                            hasConference:false,
                            access: {
                                startMeet: false,
                                addMember: false,
                                updateMember: false,
                                removeMember: false,
                                addTeam: false,
                                removeTeam: false,
                                updateTeam: false,
                                updateConference: false,
                                startConference: false,
                                isOrg: false
                            }
                        }));
                        setTimeout(() => {
                            window.location.assign("/app/home");
                        }, 100);
                    }}
                        style={{ cursor: 'pointer' }}
                    >
                        <Tooltip title={setLocale(true, "profile.back")}>
                            <Avatar size={30} style={{ backgroundColor: 'white' }}>
                                <RightSquareOutlined style={{ color: 'black' }} />
                            </Avatar>

                        </Tooltip>
                    </div>
                }
            </div>
            <div className="nav-profile-body">
                <Menu>
                    {ownedOrganisation.length > 0 && ownedOrganisation.map((organisation) => (
                        <Menu.Item
                            key={organisation.id}
                            onClick={(e) => {
                                dispatch(ChangeOrganisation({
                                    id: organisation.id,
                                    name: organisation.name,
                                    isOwner: true,
                                    userType: "owner",
                                    code: organisation.code,
                                    icon: organisation.icon,
                                    phone: organisation.phone,
                                    hasAi: organisation.hasAi,
                                    hasConference:organisation.hasConference,
                                    access: {
                                        startMeet: true,
                                        addMember: true,
                                        updateMember: true,
                                        removeMember: true,
                                        addTeam: true,
                                        removeTeam: true,
                                        updateTeam: true,
                                        updateConference: true,
                                        startConference: true,
                                        isOrg: true
                                    }
                                }));
                                setTimeout(() => {
                                    window.location.assign("/app/home");
                                }, 100);
                            }}
                            className="d-flex justify-content-between"
                        >
                            <div>
                                {organisation.icon ?
                                    <Avatar src={env.IMG_SRC + organisation.icon}></Avatar>
                                    :
                                    <Avatar>{organisation.name}</Avatar>
                                }
                                <span className="ml-2">{organisation.name}</span>
                            </div>
                        </Menu.Item>
                    ))}
                    {joinedOrganisation.length > 0 && joinedOrganisation.map((organisation) => (
                        <Menu.Item
                            key={organisation.id}
                            onClick={(e) => {
                                dispatch(ChangeOrganisation({
                                    id: organisation.id,
                                    name: organisation.name,
                                    isOwner: false,
                                    userType: 'member',
                                    code: organisation.code,
                                    icon: organisation.icon,
                                    phone: organisation.phone,
                                    hasConference:organisation.hasConference,
                                    hasAi: organisation.hasAi,
                                    access: {
                                        startMeet: organisation?.access?.start_meet || false,
                                        addMember: organisation?.access?.add_member || false,
                                        updateMember: organisation?.access?.update_member || false,
                                        removeMember: organisation?.access?.remove_member || false,
                                        addTeam: organisation?.access?.add_team || false,
                                        removeTeam: organisation?.access?.remove_team || false,
                                        updateTeam: organisation?.access?.update_team || false,
                                        updateConference: organisation?.access?.update_conference || false,
                                        startConference: organisation?.access?.start_conference || false,
                                        isOrg: true
                                    }
                                }));
                                setTimeout(() => {
                                    window.location.assign("/app/home");
                                }, 100);
                            }}
                            className="d-flex justify-content-between"
                        >
                            <div>
                                {organisation.icon ?
                                    <Avatar src={env.IMG_SRC + organisation.icon}></Avatar>
                                    :
                                    <Avatar>{organisation.name}</Avatar>
                                }
                                <span className="ml-2">{organisation.name}</span>
                            </div>
                        </Menu.Item>
                    ))}
                </Menu>
            </div>

        </div>
    );

    return (
        <>
            {ownedOrganisation.length === 0 && joinedOrganisation.length === 0 ?
                <Button className="mt-3" onClick={() => setShowJoinModel(true)}>
                    <PlusOutlined />
                    <span>{setLocale(true, "new")}</span>
                </Button>
                :
                <Dropdown
                    placement="bottomRight"
                    overlay={organisationMenu}
                    trigger={["click"]}
                >
                    <Menu className="d-flex align-item-center" mode="horizontal">
                        <Menu.Item>
                            <Tooltip title={setLocale(true, "profile.organisation")}>
                                {id ?
                                    icon ?
                                        <Avatar src={env.IMG_SRC + icon}></Avatar>
                                        :
                                        <Avatar>{name}</Avatar>
                                    :
                                    <Avatar size={50} className="mb-1 bg-white" icon={<BankOutlined style={{ color: 'black' }} />} />
                                }
                            </Tooltip>
                        </Menu.Item>
                    </Menu>
                </Dropdown>
            }
            <JoinModel visible={showJoinModel} close={handleClose} />

        </>
    );
};

const mapStateToProps = ({ org, auth }) => {
    const { id, name, type, isOwner, icon ,hasConference} = org;
    const { username } = auth;
    return { id, name, type, isOwner, icon, username ,hasConference};
};

export default connect(mapStateToProps, { ChangeOrganisation })(NavOrganisation);